// Packages
import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import SignaturePad from 'signature_pad';

// Components
import BTDTextField from 'components/BTDUI/fields/BTDTextField';
import BTDButton from 'components/BTDUI/BTDButton';
import BTDTextareaField from 'components/BTDUI/fields/BTDTextareaField';
import BTDSelectField from 'components/BTDUI/fields/BTDSelectField';
import BTDCheckboxField from 'components/BTDUI/fields/BTDCheckboxField';
import Currency from 'components/BTDUI/Currency';
import BTDTooltip from 'components/BTDUI/BTDTooltip';
import SigIcon from 'assets/sigpad.svg';

// Other
import useApi from 'hooks/useApi';

const BookingForm = ({ errors, item, onSubmit, type, admin }) => {

    const [code, setCode] = useState('');
    const [data, setData] = useState({})
	const [changedData, setChangedData] = useState({})
    const [bookings, setBookings] = useState([])
    const [total, setTotal] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [tab, setTab] = useState('magazine');
    const [coupon, setCoupon] = useState({});
    const [placements, setPlacements] = useState([]);
    const [gstRate, setGstRate] = useState(0);

    const { data: countries, isLoading: countriesLoading } = useApi('/countries');
    const { data: settings } = useApi('/settings/gst');
    const { data: returnedCoupon, post: checkCoupon } = useApi('/coupons/retrieve?with=placements', false);
    const { data: returnedPlacements } = useApi('/getplacements' + (item && item.id ? ('/' + item.id ) : ''));
    
    const signaturePadRef = useRef(null);

    useEffect(() => {

        if(returnedPlacements) {

            setPlacements(returnedPlacements)
        }
    }, [returnedPlacements]);

    useEffect(() => {

        if( item ) {

            setData(item);

            if( type === 'create' ) {

                // item supplied by search for existing customer
                setChangedData(item)
            }

            if( item.bookings ) {

                setBookings(item.bookings);
            }
        }
    }, [item])

    // load coupon 
    useEffect(() => {

        if(returnedCoupon) {

            setCoupon(returnedCoupon)
        }
    }, [returnedCoupon])

    useEffect(() => {

        if( item && item.length > 0 ) {

            setGstRate(item.gst_rate);
        }
        else if( settings && settings.length !== 0 ) {

            setGstRate(settings.value);
        }
    }, [item, settings])

    // Calculate total
    useEffect(() => {

        if( bookings ) {

            let newSubtotal = 0;
            let newDiscount = 0;

            for( let i = 0; bookings.length > i; i++ ) {

                let bookingTotal = bookings[i].issue ? bookings[i].rate : bookings[i].rate * (bookings[i].quantity ? bookings[i].quantity : 0);

                newSubtotal += bookingTotal;

                if( coupon ) {

                    if( (coupon.placements && coupon.placements.some(obj => obj.id === bookings[i].id) ) || coupon.placement_mode === 'all') {

                        newDiscount += (bookingTotal / 100) * coupon.rate;
                    }
                }
            }

            setSubtotal(newSubtotal);
            setDiscount(newDiscount);
            let gst = ( (!data.billing_country_id) || data.billing_country_id === 199 ) ? ((newSubtotal - newDiscount) / 100) * gstRate : 0;
            setTotal(newSubtotal - newDiscount + gst);

        }
    }, [bookings, coupon, settings])


    // Send order to API
    const submit = () => {

        var submitData = {...changedData};

        if( signaturePadRef && signaturePadRef.current ) {

            let signature = ! signaturePadRef.current.isEmpty() ? signaturePadRef.current.toDataURL() : null

            submitData = {...submitData, signature: signature}
        }

        onSubmit({...submitData, bookings: bookings, gst_rate: parseFloat(gstRate), coupon_id: coupon ? coupon.id : null})
    }

    //  Signature field
    useEffect(() => {

        if( ! admin ) {
            var wrapper = document.getElementById("signature-pad"),
            canvas = wrapper.querySelector("canvas")

            var signaturePad = new SignaturePad(canvas);
            signaturePad.minWidth = 1; // brush width
            signaturePad.maxWidth = 5; // brush width
            signaturePad.penColor = "#000000";
            signaturePad.backgroundColor = "#FFFFFF";

            function resizeCanvas() {
                var oldContent = signaturePad.toData();
                var ratio = Math.max(window.devicePixelRatio || 1, 1);
                canvas.width = canvas.offsetWidth * ratio;
                canvas.height = canvas.offsetHeight * ratio;
                canvas.getContext("2d").scale(ratio, ratio);
                signaturePad.clear();
                signaturePad.fromData(oldContent);
            }

            window.addEventListener('resize', resizeCanvas);
            resizeCanvas();

            // Save the signaturePad instance to the ref
            signaturePadRef.current = signaturePad;

            return () => {
                window.removeEventListener('resize', resizeCanvas);
            };
        }

    }, [admin]);

    const updatePlacement = (media, placement, property, value) => {

        const updatedPlacements = placements[media].map(item => {

            if (item.id === placement.id) {

                if( property === 'rate' ) {

                    return { ...item, rate: value, order_rate: value };
                }
                else {

                    return { ...item, [property]: value };
                }
            }
            return item;
        });

        setPlacements({...placements, [media]: updatedPlacements});

        // Update bookings
        let bookingFound = false;
        var updatedBookings = [];

        if( bookings.length > 0 ) {

            updatedBookings = bookings.map(booking => {
    
                if (placement.id === booking.placement_id) {
    
                    bookingFound = true;
    
                    return { ...booking, [property]: value };
                }
                return booking;
    
            });
        }

        if ( ! bookingFound && ! (property === 'quantity' && value === 0) ) {
            updatedBookings.push({ placement_id: placement.id, description: placement.description, rate: placement.rate, [property]: value });
        }
        
        const finalBookings = updatedBookings.filter(booking => !(booking.placement_id === placement.id && property === 'quantity' && value === 0));

        setBookings(finalBookings);
    }

    return(
        <div className='BookingForm'>

            <h3 className="form__title medium">{admin === false ? 'Make An Advertising Booking' : type === ('create' ? 'Create Booking' : 'Edit Booking')}</h3>

            {item && type === 'edit' && admin && settings && item.gst_rate != settings.value && item.billing_country_id === 199 &&
                <div style={{
                    backgroundColor: 'rgba(255, 0, 0, 0.31)',
                    borderRadius: '4px',
                    lineHeight: '1.3',
                    marginBottom: '30px',
                    padding: '20px',
                    textAlign: 'center'
                }}>Warning: GST rate has changed since this booking was made. If you would like to add items then start a new booking.</div>
            }

            <div className='form__customerDetails'>

                <div className='form__section form__section-left'>
            
                    <BTDTextField 
                        className='AnzaForm'
                        error={errors && errors.trading_name ? errors.trading_name : null} 
                        label='Trading name'
                        onChange={(value) => {
                            setData({...data, trading_name: value});
                            setChangedData({...changedData, trading_name: value});
                        }} 
                        required={true}
                        value={data.trading_name ?? ''} 
                    />

                    <BTDTextField 
                        className='AnzaForm'
                        error={errors && errors.billing_name ? errors.billing_name : null} 
                        label='Billing Name'
                        onChange={(value) => {
                            setData({...data, billing_name: value});
                            setChangedData({...changedData, billing_name: value});
                        }} 
                        value={data.billing_name ?? ''} 
                    />

                    <BTDTextareaField
                        className='AnzaForm'
                        error={errors && errors.billing_address ? errors.billing_address : null} 
                        label='Billing Address'
                        onChange={(value) => {
                            setData({...data, billing_address: value});
                            setChangedData({...changedData, billing_address: value});
                        }} 
                        required={admin ? false : true}
                        value={data.billing_address ?? ''} 
                    />

                    <BTDTextField 
                        className='AnzaForm'
                        error={errors && errors.postcode ? errors.postcode : null} 
                        label='Postcode'
                        onChange={(value) => {
                            setData({...data, postcode: value});
                            setChangedData({...changedData, postcode: value});
                        }} 
                        required={admin ? false : true}
                        value={data.postcode ?? ''} 
                    />

                    <BTDSelectField
                        className='AnzaForm'
                        error={errors && errors.country_id ? errors.country_id : null} 
                        label='Country'
                        isLoading={countriesLoading}
                        onChange={(value) => {
                            setData({...data, country_id: value});
                            setChangedData({...changedData, country_id: value});
                        }} 
                        options={countries ?? []}
                        required={admin ? false : true}
                        value={data.country_id ?? 199} 
                    />

                    <BTDSelectField
                        className='AnzaForm'
                        error={errors && errors.billing_country_id ? errors.billing_country_id : null} 
                        label='Billing Country'
                        isLoading={countriesLoading}
                        onChange={(value) => {
                            setData({...data, billing_country_id: value});
                            setChangedData({...changedData, billing_country_id: value});
                            if(value === 199) {
                                setGstRate(item && item.length > 0 ? item.gst_rate : (settings ? settings.value : 0));
                            } else {
                                setGstRate(item && item.length > 0 ? item.gst_rate : 0);
                            }
                        }} 
                        options={countries ?? []}
                        required={admin ? false : true}
                        value={data.billing_country_id ?? 199} 
                    />

                </div>

                <div className='form__section form__section-right'>

                    <BTDTextField 
                        className='AnzaForm'
                        error={errors && errors.contact_name ? errors.contact_name : null} 
                        label='Contact Name'
                        onChange={(value) => {
                            setData({...data, contact_name: value});
                            setChangedData({...changedData, contact_name: value});
                        }} 
                        required={admin ? false : true}
                        value={data.contact_name ?? ''} 
                    />

                    <BTDTextField 
                        className='AnzaForm'
                        error={errors && errors.title_position ? errors.title_position : null} 
                        label='Title/Position'
                        onChange={(value) => {
                            setData({...data, title_position: value});
                            setChangedData({...changedData, title_position: value});
                        }} 
                        required={admin ? false : true}
                        value={data.title_position ?? ''} 
                    />

                    <BTDTextField 
                        className='AnzaForm'
                        error={errors && errors.telephone ? errors.telephone : null} 
                        label='Telephone'
                        onChange={(value) => {
                            setData({...data, telephone: value});
                            setChangedData({...changedData, telephone: value});
                        }} 
                        required={admin ? false : true}
                        value={data.telephone ?? ''} 
                    />

                    <BTDTextField 
                        className='AnzaForm'
                        error={errors && errors.mobile ? errors.mobile : null} 
                        label='Mobile'
                        onChange={(value) => {
                            setData({...data, mobile: value});
                            setChangedData({...changedData, mobile: value});
                        }} 
                        value={data.mobile ?? ''} 
                    />

                    <BTDTextField 
                        className='AnzaForm'
                        error={errors && errors.email ? errors.email : null} 
                        label='Email'
                        onChange={(value) => {
                            setData({...data, email: value});
                            setChangedData({...changedData, email: value});
                        }} 
                        required={true}
                        value={data.email ?? ''} 
                    />

                    <BTDTextField 
                        className='AnzaForm'
                        error={errors && errors.billing_contact ? errors.billing_contact : null} 
                        label='Billing Contact'
                        onChange={(value) => {
                            setData({...data, billing_contact: value});
                            setChangedData({...changedData, billing_contact: value});
                        }} 
                        value={data.billing_contact ?? ''} 
                    />

                    <BTDTextField 
                        className='AnzaForm'
                        error={errors && errors.billing_email ? errors.billing_email : null} 
                        label='Billing Email'
                        onChange={(value) => {
                            setData({...data, billing_email: value});
                            setChangedData({...changedData, billing_email: value});
                        }} 
                        value={data.billing_email ?? ''} 
                    />
                </div>
            </div>

            <ul className="form__tabs">
                <li className={`form__tabs__tab ${tab === 'magazine' ? 'active' : ''}`} onClick={() => setTab('magazine')} >Magazine</li>
                <li className={`form__tabs__tab ${tab === 'website' ? 'active' : ''}`} onClick={() => setTab('website')}>Website</li>
                <li className={`form__tabs__tab ${tab === 'edm' ? 'active' : ''}`} onClick={() => setTab('edm')}>EDM - Weekly E-Newsletter</li>
                <li className={`form__tabs__tab ${tab === 'onlineArticles' ? 'active' : ''}`} onClick={() => setTab('onlineArticles')}>Online Articles</li>
            </ul>

            <div className="tableContainer">
    
                {tab === 'magazine' &&
                    <div id="magazine" className="form__table form__table-magazine">
                        <div className="form__table__row">
                            <span className="form__table__row__th form__table__row__th-description">Description</span>
                            <span className={`form__table__row__th form__table__row__th-rate ${admin ? '' : 'form__table__row__th-centre'}`}>Rate</span>
                            {placements && placements.issues && placements.issues.map((issue, index) => {
                                return(
                                    <span className="form__table__row__th form__table__row__th-issue" key={index}>{issue}</span>
                                )
                            })}
                        </div>
                        {placements && placements['Magazine'] && placements['Magazine'].map((placement, index) => {

                            const rate = placement.order_rate ? placement.order_rate : placement.rate;
                            return(

                            <div className="form__table__row row" key={index}>
                                <span className="form__table__row__td" style={{position: 'relative'}}>{placement.description}</span>
                                {admin ?
                                    (() => {
                                        const shouldShowTooltip = placement.order_rate && (placement.order_rate !== placement.rate);
                                        const inputElement = (
                                            <input
                                                className='form__table__row__td__input'
                                                onChange={(e) => updatePlacement('Magazine', placement, 'rate', e.target.value)}
                                                style={{
                                                    backgroundColor: shouldShowTooltip ? '#ff5f5f45' : '#FCFFD1',
                                                    border: 'none',
                                                    borderRight: '1px solid #E6E9EF',
                                                    height: '100%',
                                                    outline: '0',
                                                    padding: '10px',
                                                    width: '100%'
                                                }}
                                                value={placement.order_rate ? placement.order_rate : placement.rate}
                                            />
                                        );

                                        return shouldShowTooltip ? (
                                            <BTDTooltip text={'Regular rate: ' + placement.rate}>
                                                {inputElement}
                                            </BTDTooltip>
                                        ) : inputElement;
                                    })()
                                    :
                                    <span className="form__table__row__td form__table__row__td-centre rate">
                                        {(rate === 0 || rate === null) ? 'POA' : <Currency value={rate} />}
                                    </span>
                                }
                                {placement && placement.issues.map((issue, index) => {
                                return(
                                    <>
                                    <span className={`form__table__row__th form__table__row__td-issue issue${index + 1}`}>{issue.date}</span>
                                    <span className={`form__table__row__td form__table__row__td checkbox${index + 1}`}>
                                        <BTDCheckboxField
                                            checked={issue.booked}
                                            onChange={(value) => {

                                                const newIssues = placement.issues.map(item => item.date === issue.date ? {'date': item.date, 'booked': value} : item);
                                                
                                                const updatedPlacements = placements['Magazine'].map(item => {

                                                    if (placement.id === item.id) {

                                                        return { ...item, issues: newIssues };
                                                    }
                                                    return item;
                                                });

                                                setPlacements({...placements, 'Magazine': updatedPlacements});

                                                if(value) {
                                                    setBookings([...bookings, {placement_id: placement.id, rate: parseFloat(placement.rate), issue: issue.date, description: placement.description}])
                                                }
                                                else {
                                                    const updatedBookings = bookings.filter(booking => !(booking.placement_id === placement.id && booking.issue === issue.date));
                                                    setBookings(updatedBookings);
                                                }
                                            }}
                                            style={{display: 'block', margin: '0 auto'}}
                                        />
                                    </span>
                                    </>
                                )
                                })}
                            </div>
                            )
                            })
                        }
                    </div>
                }
                

                {tab === 'website' &&
                    <div id="website" className="form__table form__table-website">
                        <div className="form__table__row">
                            <span className="form__table__row__th form__table__row__th-desktopDescription">Description</span>
                            <span className={`form__table__row__th form__table__row__th-desktopRate ${admin ? '' : 'form__table__row__th-centre'}`}>Rate {admin ? ' SG$ ': ''}</span>
                            <span className="form__table__row__th form__table__row__th form__table__row__th-desktop">Uplift Date
                            <span className='infoIcon'>
                                <BTDTooltip text='Enter a date, month, or if you are unsure, simply "TBC". If you need extra space, use the comments section below.'>
                                    <span className="infoIcon__icon">i</span>
                                </BTDTooltip>
                            </span>
                            </span>
                            <span className="form__table__row__th form__table__row__th form__table__row__th-desktop">No. of Months</span>
                        </div>
                        {placements && placements['Website'] && placements['Website'].map((placement, index) => {
                            const hasChanged = placement.order_rate || typeof(placement.order_rate) !== 'undefined';
                            const rate = hasChanged ? placement.order_rate : placement.rate;
                            return(
                                <div className="form__table__row" key={index}>
                                    <span className="form__table__row__td">{placement.description}</span>
                                    {admin ?
                                        (() => {
                                            const shouldShowTooltip = hasChanged && (placement.order_rate !== placement.rate);
                                            const inputElement = (
                                                <input
                                                    className='form__table__row__td__input'
                                                    onChange={(e) => updatePlacement('Website', placement, 'rate', e.target.value)}
                                                    style={{
                                                        backgroundColor: shouldShowTooltip ? '#ff5f5f45' : '#FCFFD1',
                                                        border: 'none',
                                                        borderRight: '1px solid #E6E9EF',
                                                        height: '100%',
                                                        outline: '0',
                                                        padding: '10px',
                                                        width: '100%'
                                                    }}
                                                    value={rate}
                                                />
                                            );
    
                                            return shouldShowTooltip ? (
                                                <BTDTooltip text={'Regular rate: ' + placement.rate}>
                                                    {inputElement}
                                                </BTDTooltip>
                                            ) : inputElement;
                                        })()
                                    :
                                    <span className="form__table__row__td form__table__row__td-centre rate">{(rate === 0 || rate === null) ? 'POA' : <Currency value={rate} />}</span>
                                    }
                                    <span className="form__table__row__th form__table__row__td-mobileDateLabel">Uplift Date
                                    <span className='infoIcon'>
                                        <BTDTooltip text='Enter a date, month, or if you are unsure, simply "TBC". If you need extra space, use the comments section below.'>
                                            <span className="infoIcon__icon">i</span>
                                        </BTDTooltip>
                                    </span>
                                    </span>
                                    <span className="form__table__row__td form__table__row__td-dateInput">
                                        <input 
                                            className="form__input form__input-table" 
                                            maxLength="27"
                                            onChange={(e) => {
                                                updatePlacement('Website', placement, 'date', e.target.value);
                                            }} 
                                            type="text" 
                                            value={placement.date ?? ''}
                                        />
                                    </span>
                                    <span className="form__table__row__th form__table__row__td-mobileQuantityLabel">No. of Months</span>
                                    <span className="form__table__row__td form__table__row__td-centre form__table__row__td-quantityInput">
                                        <input 
                                            className="form__input form__input-table Quantity" 
                                            min="0"
                                            onChange={(e) => {
                                                updatePlacement('Website', placement, 'quantity', e.target.value);
                                            }}  
                                            type="number" 
                                            value={placement.quantity ?? ''}
                                        />
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                }

                {tab === 'edm' &&
                    <div id="edm" className="form__table form__table-edm">
                        <div className="form__table__row">
                            <span className="form__table__row__th form__table__row__th-desktopDescription">Description</span>
                            <span className={`form__table__row__th ${admin ? '' : 'form__table__row__th-centre'} form__table__row__th-desktopRate`}>Rate {admin ? ' SG$ ': ''}</span>
                            <span className="form__table__row__th form__table__row__th form__table__row__th-desktop">Date of Advertising
                            <span className='infoIcon'>
                                <BTDTooltip text='Enter a date, month, or if you are unsure, simply "TBC". If you need extra space, use the comments section below.'>
                                    <span className="infoIcon__icon">i</span>
                                </BTDTooltip>
                            </span>
                            </span>
                            <span className="form__table__row__th form__table__row__th-desktop form__table__row__th">Quantity</span>
                        </div>
                        {placements && placements['EDM'] && placements['EDM'].map((placement, index) => {
                            const hasChanged = placement.order_rate || typeof(placement.order_rate) !== 'undefined';
                            const rate = hasChanged ? placement.order_rate : placement.rate;
                            return(
                            <div className="form__table__row" key={index}>
                                <span className="form__table__row__td">{placement.description}</span>
                                {admin ?
                                    (() => {
                                        const shouldShowTooltip = hasChanged && (placement.order_rate !== placement.rate);
                                        const inputElement = (
                                            <input
                                                className='form__table__row__td__input'
                                                onChange={(e) => updatePlacement('EDM', placement, 'rate', e.target.value)}
                                                style={{
                                                    backgroundColor: shouldShowTooltip ? '#ff5f5f45' : '#FCFFD1',
                                                    border: 'none',
                                                    borderRight: '1px solid #E6E9EF',
                                                    height: '100%',
                                                    outline: '0',
                                                    padding: '10px',
                                                    width: '100%'
                                                }}
                                                value={rate}
                                            />
                                        );

                                        return shouldShowTooltip ? (
                                            <BTDTooltip text={'Regular rate: ' + placement.rate}>
                                                {inputElement}
                                            </BTDTooltip>
                                        ) : inputElement;
                                    })()
                                :
                                <span className="form__table__row__td form__table__row__td-centre rate">{(rate === 0  || rate === null) ? 'POA' : <Currency value={rate} />}</span>
                                }
                                <span className="form__table__row__th form__table__row__td-mobileDateLabel">Date of Advertising
                                <span className='infoIcon'>
                                    <BTDTooltip text='Enter a date, month, or if you are unsure, simply "TBC". If you need extra space, use the comments section below.'>
                                        <span className="infoIcon__icon">i</span>
                                    </BTDTooltip>
                                </span>
                                </span>
                                <span className="form__table__row__td form__table__row__td-dateInput">
                                    <input 
                                        className="form__input form__input-table" 
                                        maxLength="27"
                                        onChange={(e) => {
                                            updatePlacement('EDM', placement, 'date', e.target.value);
                                        }}
                                        type="text" 
                                        value={placement.date ?? ''}
                                    />
                                </span>
                                <span className="form__table__row__th form__table__row__td-mobileQuantityLabel">Quantity</span>
                                <span className="form__table__row__td form__table__row__td-centre form__table__row__td-quantityInput">
                                    <input 
                                        className="form__input form__input-table Quantity" 
                                        min="0"
                                        onChange={(e) => {
                                            updatePlacement('EDM', placement, 'quantity', e.target.value);
                                        }}
                                        type="number" 
                                        value={placement.quantity ?? ''}
                                    />
                                </span>
                            </div>
                            )
                        })}
                    </div>
                }

                
                {tab === 'onlineArticles' &&
                    <div id="onlineArticles" className="form__table form__table-onlineArticles">
                        <div className="form__table__row">
                            <span className="form__table__row__th form__table__row__th-desktopDescription">Description</span>
                            <span className={`form__table__row__th form__table__row__th-desktopRate ${admin ? '' : 'form__table__row__th-centre'}`}>Rate {admin ? ' SG$ ': ''}</span>
                            <span className="form__table__row__th form__table__row__th form__table__row__th-desktop">Uplift Date
                                <span className='infoIcon'>
                                    <BTDTooltip text='Enter a date, month, or if you are unsure, simply "TBC". If you need extra space, use the comments section below.'>
                                        <span className="infoIcon__icon">i</span>
                                    </BTDTooltip>
                                </span>
                            </span>
                            <span className="form__table__row__th form__table__row__th form__table__row__th-desktop">Quantity</span>
                        </div>
                        {placements && placements['Online Articles'] && placements['Online Articles'].map((placement, index) => {
                            const hasChanged = placement.order_rate || typeof(placement.order_rate) !== 'undefined';
                            const rate = hasChanged ? placement.order_rate : placement.rate;
                            return(
                                <div className="form__table__row" key={index}>
                                    <span className="form__table__row__td">{placement.description}</span>
                                    {admin ?
                                        (() => {
                                            const shouldShowTooltip = hasChanged && (placement.order_rate !== placement.rate);
                                            const inputElement = (
                                                <input
                                                    className='form__table__row__td__input'
                                                    onChange={(e) => updatePlacement('Online Articles', placement, 'rate', e.target.value)}
                                                    style={{
                                                        backgroundColor: shouldShowTooltip ? '#ff5f5f45' : '#FCFFD1',
                                                        border: 'none',
                                                        borderRight: '1px solid #E6E9EF',
                                                        height: '100%',
                                                        outline: '0',
                                                        padding: '10px',
                                                        width: '100%'
                                                    }}
                                                    value={rate}
                                                />
                                            );
    
                                            return shouldShowTooltip ? (
                                                <BTDTooltip text={'Regular rate: ' + placement.rate}>
                                                    {inputElement}
                                                </BTDTooltip>
                                            ) : inputElement;
                                        })()
                                    :
                                    <span className="form__table__row__td form__table__row__td-centre rate">{(rate === 0  || rate === null) ? 'POA' : <Currency value={rate} />}</span>
                                    }
                                    <span className="form__table__row__td form__table__row__td-dateInput">
                                        <input 
                                            className="form__input form__input-table" 
                                            maxLength="27"
                                            onChange={(e) => {
                                                updatePlacement('Online Articles', placement, 'date', e.target.value);
                                            }}
                                            type="text" 
                                            value={placement.date ?? ''}
                                        />
                                    </span>
                                    <span className="form__table__row__th form__table__row__td-mobileDateLabel">Uplift Date
                                    <span className='infoIcon'>
                                        <BTDTooltip text='Enter a date, month, or if you are unsure, simply "TBC". If you need extra space, use the comments section below.'>
                                            <span className="infoIcon__icon">i</span>
                                        </BTDTooltip>
                                    </span>
                                    </span>
                                    <span className="form__table__row__th form__table__row__td-mobileQuantityLabel">Quantity</span>
                                    <span className="form__table__row__td form__table__row__td-centre form__table__row__td-quantityInput">
                                        <input 
                                            className="form__input form__input-table Quantity" 
                                            min="0"
                                            name="websiteAds[{{ $placement->id }}][no_of_months]" 
                                            onChange={(e) => {
                                                updatePlacement('Online Articles', placement, 'quantity', e.target.value);
                                            }}
                                            type="number" 
                                            value={placement.quantity ?? ''}
                                        />
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                }

                <div className="totalDiv">
                    <p className='totalDiv__item' style={{marginRight: '20px'}}><span>Subtotal: </span><Currency value={subtotal}/></p>
                    {coupon && Object.keys(coupon).length > 0 &&
                        <>
                            <p className='totalDiv__item' style={{marginRight: '20px'}}><span>Discount: </span><Currency value={discount}/>{' (' + (coupon ? coupon.code : '') + ')'}</p>
                        </>
                    }
                    {settings && settings.length !== 0 && ( (!data.billing_country_id) || data.billing_country_id === 199 ) &&
                        <p className='totalDiv__item'><span>GST ({settings.value}%): </span><Currency value={((subtotal - discount) / 100) * settings.value}/></p>
                    }
                    <p className='totalDiv__item bold' style={{fontWeight: '600'}}><span>Total: </span><Currency value={total}/></p>
                </div>

                <div style={{ alignItems: 'center', display: 'flex', margin: '10px 0 0 10px'}}>

                    {coupon && Object.keys(coupon).length > 0 ? 

                        <BTDButton
                            colour='red'
                            formButton={true}
                            label='Remove coupon'
                            onClick={() => {
                                setCoupon(null);
                                setCode('');
                            }}
                            size='small'
                        />
                        :
                    <>
                        <span>Coupon: </span>
                        <input 
                            onChange={(e) => setCode(e.target.value)} 
                            style={{ border: '1px solid #E6E9EF', borderRadius: '0.25rem', margin: '0 15px', outline: 'none', padding: '11px 10px'}} 
                            value={code ? code.replace(' ', '') : ''} 
                        />
                        <BTDButton
                            formButton={true}
                            label='Apply'
                            onClick={async () => {
                                if(! await checkCoupon({code: code})) {
                                    setCoupon(null);
                                };
                                
                            }}
                            size='small'
                            
                        />
                    </>
                    }
                </div>

                
            </div>

            <h2>Additional Comments</h2>
            <textarea 
                rows="5" 
                className="form__textarea form__textarea-comments" 
                onChange={(e) => {
                    setData({...data, additional_comments: e.target.value});
                    setChangedData({...changedData, additional_comments: e.target.value});
                }}
                placeholder="Write any additional comments you have here"
                style={{outline: 'none'}}
                value={data.additional_comments ?? ''}
            >
                {data.additional_comments}
            </textarea>

            {! admin &&
            <>
                <h2>Terms & Conditions</h2>
                <div className="form__scrollingText">
                    <h4 className="form__scrollingText__subheading">PAYMENT TERMS</h4>
                    <p className="form__scrollingText__text">Full payment must be made within 30 days of date of invoice.  Late payment risks interest, costs and delays in inclusion or withdrawal of advertisements.  For information on overseas payments please contact ANZA.</p>
                    <p className="form__scrollingText__text">Advertiser must pay any bank and bank account nominated by ANZA transfer fees.</p>
                    <h4 className="form__scrollingText__subheading">TERMS AND CONDITIONS</h4>
                    <h4 className="form__scrollingText__subheading">EDM</h4>
                    <p className="form__scrollingText__text">Cancellations must be made in writing no less than one week prior to uplift date.</p>
                    <p className="form__scrollingText__text">If cancellation is received after the uplift date, full charges will be incurred.</p>
                    <p className="form__scrollingText__text">ANZA reserves the right to withdraw advertising if it is seen to be unfit for purpose.</p>
                    <h4 className="form__scrollingText__subheading">MAGAZINE</h4>
                    <p className="form__scrollingText__text">Cancellations must be made in writing to marketing@anza.org.sg.</p>
                    <p className="form__scrollingText__text">Cancellations must be received one month prior to the booking deadline for month of publication.</p>
                    <p className="form__scrollingText__text">If cancellation is received after the booking deadline, full charges will be incurred.</p>
                    <p className="form__scrollingText__text">ANZA reserves the right to withdraw advertising from a publication if payment terms are not met.</p>
                    <h4 className="form__scrollingText__subheading">WEBSITE</h4>
                    <p className="form__scrollingText__text">Cancellations must be made in writing no less than two weeks prior to uplift date.</p>
                    <p className="form__scrollingText__text">If cancellation is received after the cancellation deadline, full charges will be incurred.</p>
                    <p className="form__scrollingText__text">ANZA reserves the right to withdraw advertising from the website if payment terms are not met.</p>
                    <p className="form__scrollingText__text">ANZA reserves the right to withdraw advertising if it is seen to be unfit for purpose.</p>
                </div>

                <h2 className="form__heading-authorisation">Authorisation</h2>
                <div className="form__boxContainer">
                    <div style={{display: 'flex', marginBottom: '10px'}}>
                        <BTDCheckboxField
                            checked={data.agreed_conditions ?? false}
                            className='AnzaRegular'
                            label='I have read and agree with the conditions outlined in the ANZA Advertising Pack.'
                            onChange={(value) => {
                                setData({...data, agreed_conditions: value});
                                setChangedData({...changedData, agreed_conditions: value});
                            }}
                            required
                        />
                        <sup className="form__label__star">*</sup>
                    </div>
                    <div style={{display: 'flex'}}>
                        <BTDCheckboxField
                            checked={data.notify_changes ?? false}
                            className='AnzaRegular'
                            label='I will notify ANZA of any cancellations or changes, in writing, in accordance with the relevant terms and conditions.'
                            onChange={(value) => {
                                setData({...data, notify_changes: value});
                                setChangedData({...changedData, notify_changes: value});
                            }}
                            required
                        />
                        <sup className="form__label__star">*</sup>
                    </div>
                </div>
                
                <div className="signatureTitleDiv">
                    <h2 className="signatureTitle">Signature <sup className="form__label__star">*</sup></h2>
                    <div className="button__clear" onClick={() => {
                        if (signaturePadRef.current) {
                            signaturePadRef.current.clear();
                        }
                    }}>Clear</div>
                </div>
                <div id="signature-pad" className="m-signature-pad" style={{marginBottom: '30px'}}>
                    <div className="m-signature-pad--body">
                        <img src={SigIcon} alt="Icon of a pen writing" className="penIcon"/>
                        <canvas id="signatureCanvas"></canvas>
                        <input type="hidden" name="signature" id="signature" value=""/>
                    </div>
                </div>
                {errors && errors.signature &&
                    <p className='red' style={{margin: '-30px 0 30px 0'}}>{errors.signature}</p>
                } 
            </>
            }

            <BTDButton 
                formButton={true}
                label={type === 'create' ? 'Submit Booking' : admin ? 'Update Booking' : 'Approve Booking'}
                onClick={() => {submit()}} 
            ></BTDButton>

        </div>
    )

}

BookingForm.propTypes = {
	errors: PropTypes.object,
	item: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	onSubmit: PropTypes.func,
    type: PropTypes.string,
    admin: PropTypes.bool
}

BookingForm.defaultProps = {
    errors: {},
    item: null,
	onSubmit: () => {alert('onSubmit is not set')},
    type: 'create',
    admin: true
}

export default BookingForm;